import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import Share from 'components/layout/Share';
import { ReactComponent as BackIcon } from 'ui/chevron-back.svg';
import { ReactComponent as LogoApp } from 'ui/logo.svg';
import { ReactComponent as LogoDeTijd } from 'ui/de-tijd.svg';
import { ReactComponent as ColofonIcon } from 'ui/info.svg';

const StyledBackIcon = styled.span`
    width: 6px;
    height: 12px;

    margin-right: 10px;
`;

const StyledLogoDeTijdWrapper = styled.a`
    color: currentColor;
    transform: translateY(5px);
`;

const StyledLogoDeTijd = styled(LogoDeTijd)`
    max-width: 34px;
`;

const StyledLogo = styled(LogoApp)`
    max-width: 30px;
`;

const StyledLogoWrapper = styled(Link)`
    color: currentColor;
`;

const StyledHeader = styled.header`
    position: relative;
    z-index: 100;

    width: 100%;
    height: 40px;

    background-color: #fef7ea;
    color: #A29583;

    ${props => props.sticky && css`
        position: sticky;
        top: 0;
        z-index: 999;
        height: 40px;

        background-color: #001F42;
        color: #B0CFE7;
    `}
`;

const StyledHeaderInner = styled.div`
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

    padding: 5px 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledActions = styled.div`
    display: flex;

    > *:not(:last-child) {
        &::after {
            content: '|';
            margin: 0 20px;
            color: currentColor;
            opacity: .5;
        }
    }
`;

const StyledColofon = styled(Link)`
    color: inherit;
    text-decoration: none;
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: Roboto, sans-serif;
`;


const Header = ({sticky, deTijdLogo}) => {
    const props = {
        sticky,
        initialPose: !sticky ? "start" : "",
        pose: !sticky ? "done" : "",
    };
    return (
    <StyledHeader {...props}>
        <StyledHeaderInner
            sticky={sticky}>
            {deTijdLogo ? (
                <StyledLogoDeTijdWrapper
                    title="Terug naar www.tijd.be"
                    href="https://www.tijd.be"
                    target="_blank"
                    rel="noreferrer nofollow">
                    <StyledBackIcon>
                        <BackIcon />
                    </StyledBackIcon>
                    <StyledLogoDeTijd />
                </StyledLogoDeTijdWrapper>
            ) : (
                <StyledLogoWrapper to="/intro">
                    <StyledBackIcon>
                        <BackIcon />
                    </StyledBackIcon>
                    <StyledLogo />
                </StyledLogoWrapper>
            )}
            <StyledActions>
                <StyledColofon to="/colofon" title="Ga naar colofon">
                    <ColofonIcon width="20" height="20" title="Colofon" />
                </StyledColofon>
                <Share />
            </StyledActions>
        </StyledHeaderInner>
    </StyledHeader>);
};

export default Header;