import React from 'react';
import styled from 'styled-components';
import { FulfillingSquareSpinner } from 'react-epic-spinners';

const LoaderContainer = styled.div`
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    justify-content: center;
    align-items: center;
`

const Loader = ({ error, pastDelay }) => {
    if (!pastDelay && !error) {
        return null;
    }

    else if (pastDelay && !error) {
        return (
            <LoaderContainer>
                <FulfillingSquareSpinner color="#001F42" />
            </LoaderContainer>
        );
    }

    return null;
}

export default Loader;
