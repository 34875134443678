import Article from 'components/layout/Article';
import {connect} from 'react-redux';

import {setArticleProgress} from 'actions';
import {activeArticleIdSelector} from 'selectors';

const mapStateToProps = (state, ownProps) => ({
    activeArticleId: activeArticleIdSelector(state, ownProps),
});

const mapDispatchToProps = dispatch => ({
    setArticleProgress: (id, progress, inView) => {
        dispatch(setArticleProgress(id, progress, inView))
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Article);
