import { createSelector } from 'reselect';
import {maxBy} from 'lodash';

const articlesProgressSelector = state => state.articlesProgress;

export const articlesInViewSelector = createSelector(
    articlesProgressSelector,
    items => items.filter(article => article.inView)
);

export const activeArticleIdSelector = createSelector(
    articlesInViewSelector,
    (items) => {
        const activeItem =  maxBy(items, 'progress');
        return activeItem ? activeItem.id : null
    },
);
