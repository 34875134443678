import React from 'react';
import ReactDOM from 'react-dom';
import 'sanitize.css';

import 'index.css';
import data from 'data.json';
import App from 'components/app/App';
import * as serviceWorker from './serviceWorker';

import {bootstrap} from 'bootstrap';

bootstrap(data.items);
ReactDOM.render(<App data={data} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
