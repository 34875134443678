import { INIT_ARTICLES, SET_ARTICLE_PROGRESS } from 'actions';

const initialState = {
    articlesProgress: [],
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT_ARTICLES:
            const {articles} = action.payload;

            return {
                ...state,
                articlesProgress: articles.map(({id}) => ({
                    id,
                    progress: 0,
                    inView: false,
                }))
            };

        case SET_ARTICLE_PROGRESS:
            const {id, progress, inView} = action.payload;
            return {
                ...state,
                articlesProgress: state.articlesProgress.map(article => {
                    if (article.id === id) {
                        return {
                            ...article,
                            progress,
                            inView,
                        }
                    }

                    return article;
                })
            };

        default:
            return state;
    }
};

export { rootReducer };
