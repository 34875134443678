import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';

import { Link } from 'react-router-dom';

import arrow from 'ui/arrow-down.svg';

const AnimatedStartReading = posed.span({
    visible: {
        y: 0,
    },
    hidden: {
        y: -20,
    },
})

const StyledStartReading = styled(AnimatedStartReading)`
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    color: #A29583;

    img {
        transition: transform 450ms ease-in-out;
    }

    &:hover,
    &:focus {
        outline: 0;
        opacity: .8;

        > img {
            transform: translateY(10px);
        }
    }
`;

const StyledLink = styled(Link)`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;

    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;

    color: inherit;
    text-decoration: none;
`;

const StartReading = ({show, link}) => (
    <StyledLink to={link} title="Start met lezen">
        <StyledStartReading
            initialPose="init"
            pose={show ? 'visible':'hidden'}>
            Start
            <img alt="" src={arrow} width="12" height="6" style={{marginTop: '10px'}} />
        </StyledStartReading>
    </StyledLink>
);

export default StartReading;