import React from 'react';
import styled, { css } from 'styled-components';

import { getImageUrl} from 'helpers/image';

const StyledHero = styled.header`
    position: relative;
    z-index: 10;
`;

const StyledHeroBackground = styled.div`
    width: 100%;
    min-height: 300px;
    opacity: .1;

    background-size: cover;
    background-position: center;
    background-color: #001F42;

    transition: opacity 50ms ease-in;

    @media screen and (min-width: 768px) {
        min-height: 750px;
    }

    ${props => props.backgroundLoaded && css`
        background-image: url(${props => props.cover});
        opacity: 1;
    `}
`;

const StyledHeroContent = styled.div`
    position: relative;
    margin: -30px 10px 0 0;
    padding: 20px;
    background-color: #FEF7EA;

    @media screen and (min-width: 768px) {
        margin: -60px 60px 0;
        padding: 0 90px;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 240px;

        box-shadow: 0px -50px 50px rgba(0, 0, 0, 0.1);
    }
`;

const StyledHeroContentInner = styled.div`
    max-width: 1440px;

    margin: 0 auto;
    padding: 20px 0;

    @media screen and (min-width: 768px) {
        padding: 60px 0;
    }
`;

const StyledHeroTitle = styled.h1`
    font-size: 42px;
    line-height: 48px;
    font-weight: 400;

    color: #001F42;

    padding: 0;

    @media screen and (min-width: 768px) {
        font-size: 60px;
        line-height: 70px;
    }
`;

const StyledHeroDescription = styled.div`
    font-size: 24px;
    line-height: 30px;
    color: #A29583;

    margin: 7.5px 0 15px;

    @media screen and (min-width: 768px) {
        font-size: 30px;
        line-height: 42px;

        margin: 15px 0 30px;
    }
`;

const StyledHeroAuthor = styled.div`
    font-size: 16px;
    line-height: 20px;
    font-weight: 800;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;

    color: #A29583;
`;

const StyledHeroBreak = styled.hr`
    margin: 15px 0 30px;
    border: 0;
    border-bottom: 1px solid #E3D7C4;

    @media screen and (min-width: 768px) {
        margin-top: 7.5px 0 30px;
    }
`;

class Hero extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundLoaded: false,
            coverSrc: getImageUrl(props.cover),
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.backgroundLoaded  && this.props.inView) {
            this.preloadBackgroundImage();
        }
    }

    preloadBackgroundImage() {
        const {coverSrc} = this.state;

        const image = new Image();

        image.addEventListener('load', () => {
            this.setState({
                backgroundLoaded: true,
            });
        });
        image.src = coverSrc;
    }

    render() {
        const {backgroundLoaded, coverSrc} = this.state;
        const {title, description, author} = this.props;

        return (
            <StyledHero>
                <StyledHeroBackground
                    backgroundLoaded={backgroundLoaded}
                    cover={coverSrc} />
                <StyledHeroContent>
                    <StyledHeroContentInner>
                        <StyledHeroTitle>{title}</StyledHeroTitle>
                        <StyledHeroDescription>{description}</StyledHeroDescription>
                        <StyledHeroBreak />
                        {author && <StyledHeroAuthor>{author}</StyledHeroAuthor>}
                    </StyledHeroContentInner>
                </StyledHeroContent>
            </StyledHero>
        );
    }
}

export default Hero;