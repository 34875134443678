import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';

import {store} from 'constants/store';
import Homepage from 'pages/Homepage';
import Colofon from 'pages/Colofon';

class App extends React.Component {
    render = () => {
        const {data} = this.props;
        const routes = data.items.map(route => route.id).join('|');

        return (
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route
                            path={["/", "/intro"]}
                            component={(props) => <Homepage {...this.props} {...props} />}
                            exact
                        />
                        <Route
                            path={`/(${routes})/`}
                            component={(props) => <Homepage {...this.props} {...props} />}
                            exact
                        />
                        <Route
                            path="/colofon"
                            component={(props) => (
                                <Colofon
                                    title={data.colofon.title}
                                    contentBlocks={data.colofon.contentBlocks}
                                    {...props} />)
                            }
                            exact
                        />
                        <Route>
                            <Redirect to="/" />
                        </Route>
                    </Switch>
                </Router>
            </Provider>
        )
    }
}

export default App;