export const INIT_ARTICLES = 'INIT_ARTICLES';
export const SET_ARTICLE_PROGRESS = 'SET_ARTICLE_PROGRESS';

export const initArticles = (articles) => ({
    type: INIT_ARTICLES,
    payload: {
        articles,
    },
});


export const setArticleProgress = (id, progress, inView) => ({
    type: SET_ARTICLE_PROGRESS,
    payload: {
        id,
        progress,
        inView,
    },
});
