import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import { ReactComponent as InhoudIcon } from 'ui/pencil.svg';
import { ReactComponent as ConceptIcon } from 'ui/cursor.svg';
import { ReactComponent as ColofonIcon } from 'ui/info.svg';

const StyledFooter = styled.footer`
    border-top: 1px solid #E3D7C4;
    padding-top: 15px;

    max-width: 540px;
    margin-left: auto;
    margin-right: auto;

    padding-left: 15px;
    padding-right: 15px;

    font-size: 16px;
    line-height: 24px;

    color: #A29583;

    @media screen and (min-width: 768px) {
        padding-left: 0;
        padding-right: 0;
    }

    a {
        color: #001F42;
        text-decoration: none;

        background-image: linear-gradient(to bottom, #E3D7C4, #E3D7C4);
        background-size: 100% 1px;
        background-position: bottom;

        &:hover,
        &:focus {
            background-image: linear-gradient(to bottom, #001F42, #001F42);
        }
    }
`;

const StyledFooterSection = styled.div`

`;

const StyledFooterTitle = styled.h4`
    display: inline-block;
    font-weight: 400;

    margin-right: 5px;
`;

const StyledIcon = styled.span`
    margin-right: 8px;
`;

const Footer = () => (
    <StyledFooter>
        <StyledFooterSection>
            <StyledIcon>
                <InhoudIcon width="15" height="15" />
            </StyledIcon>
            <StyledFooterTitle>Inhoud: </StyledFooterTitle>
            <a href="https://www.tijd.be" target="_blank" rel="noopener noreferrer">DeTijd</a>
        </StyledFooterSection>
        <StyledFooterSection>
            <StyledIcon>
                    <ConceptIcon width="14" height="14" />
            </StyledIcon>
            <StyledFooterTitle>Concept &amp; Development: </StyledFooterTitle>
            <a href="https://www.wieni.be" target="_blank" rel="noopener noreferrer">Wieni</a>
        </StyledFooterSection>
        <StyledFooterSection>
            <StyledIcon>
                <ColofonIcon width="16" height="16" />
            </StyledIcon>
            <Link to="/colofon">Colofon</Link>
        </StyledFooterSection>
    </StyledFooter>
);

export default Footer;
