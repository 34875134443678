import React from 'react';
import styled from 'styled-components';

import Header from 'components/layout/Header';
import ContentBlocks from 'components/content-blocks/ContentBlocks';

const StyledColofonWrapper = styled.div`
    margin: 40px 0;
`;

class Colofon extends React.Component {
    componentDidMount = () => {
        window.scrollTo(window.scrollX, 0);
    }
    render() {
        const {contentBlocks} = this.props;

        return (
            <React.Fragment>
                <Header
                    sticky />
                <StyledColofonWrapper>
                    {contentBlocks.length && <ContentBlocks
                            blocks={contentBlocks} />}
                    </StyledColofonWrapper>
            </React.Fragment>
        )
    }
}

export default Colofon;