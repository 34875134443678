import React from 'react';
import styled from 'styled-components';

const metadataUrl = (() => {
    return window.location;
})();

const metadataTitle = (() => {
    return encodeURIComponent(document.querySelector("meta[property='og:title']").getAttribute("content"));
})()

const metadataDescription = (() => {
    return encodeURIComponent(document.querySelector("meta[property='og:description']").getAttribute("content"));
})()

const networks = [{
    "name": "facebook",
    "title": "Delen op Facebook",
    "url": `http://www.facebook.com/sharer.php?u=${metadataUrl}`,
    "icon": {
        "name": "icon-facebook",
        "width": "7",
        "height": "15",
    },
}, {
    "name": "linkedin",
    "title": "Delen op LinkedIn",
    "url": `https://www.linkedin.com/shareArticle?mini=true&url=${metadataUrl}&title=${metadataTitle}&summary=${metadataDescription}`,
    "icon": {
        "name": "icon-linkedin",
        "width": "15",
        "height": "15",
    },
}, {
    "name": "twitter",
    "title": "Delen op Twitter",
    "url": `https://twitter.com/intent/tweet?url=${metadataUrl}&text=${metadataTitle}`,
    "icon": {
        "name": "icon-twitter",
        "width": "17",
        "height": "15",
    },
}];

const StyledShare = styled.div`
    display: flex;
`;

const StyledShareTitle = styled.div`
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: Roboto, sans-serif;

    margin-right: 20px;
`;

const StyledShareNetworks = styled.div`
    display: inline-flex;
    margin: -10px;

    > *:not(:last-child) {
        margin-right: 1px;
    }
`;

const StyledShareNetwork = styled.a`
    display: block;
    width: ${props => props.width};
    height: ${props => props.height};
    padding: 10px;

    text-decoration: none;
    color: inherit;

    transition: opacity 250ms;

    &:hover,
    &:focus {
        opacity: .5;
    }
`;

const Share = () => (
    <StyledShare>
        <StyledShareTitle>Share</StyledShareTitle>
        <StyledShareNetworks>
            {networks.map(({name, title, url, icon}) => (
                <StyledShareNetwork key={name} width={icon.width} height={icon.height} title={title} href={url} target="_blank" rel="noopener noreferrer">
                    <svg width={icon.width} height={icon.height}><use xlinkHref={`#${icon.name}`} width={icon.width} height={icon.height} /></svg>
                </StyledShareNetwork>))}
        </StyledShareNetworks>
    </StyledShare>
);

export default Share;