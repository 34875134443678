import {connect} from 'react-redux';

import {activeArticleIdSelector} from 'selectors';
import TableOfContents from 'components/layout/TableOfContents';

const mapStateToProps = (state, ownProps) => ({
    items: ownProps.table.map(article => ({
        ...article,
        ...state.articlesProgress.filter(item => item.id === article.target)[0]
    })),
    activeItemId: activeArticleIdSelector(state, ownProps),
});

const mapDispatchToProps = dispatch => ({
});


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TableOfContents);
