import React from 'react';
import styled from 'styled-components';

const StyledMain = styled.div`
    margin-bottom: 100px;

    @media screen and (min-width: 1040px) {
        flex-direction: column;
        flex: 1 1 1040px;

        overflow: hidden;

        margin-bottom: 30px;
    }
`;

const Main = ({children}) => (
    <StyledMain>
        {children}
    </StyledMain>
);

export default Main;