import React from 'react';
import styled from 'styled-components';

const StyledTableOfContentsLink = styled.span`
    color: ${props => props.highlight ? '#001F42' : '#A29583'};
    text-decoration: none;
    position: relative;

    font-weight: ${props => props.highlight ? 800 : 400};

    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
    }

    &::before {
        height: 1px;
        width: 100%;
        background: #E3D7C4;
    }
`;

const StyledTableOfContentsProgress = styled.span.attrs({
    style: ({progress}) => ({
        width: `${progress}%`
    })
})`
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    background: #001F42;

    transition: width 165ms ease-in-out;
`

/*
styled-components.browser.esm.js:1525 Over 200 classes were generated for component styled.span.
Consider using the attrs method, together with a style object for frequently changed styles.
Example:
  const Component = styled.div.attrs({
    style: ({ background }) => ({
      background,
    }),
  })`width: 100%;`

  <Component />
*/

const TableOfContentsLink = ({highlight, text, aside, progress, handleClick}) => (
    <StyledTableOfContentsLink
        onClick={handleClick ? handleClick : null}
        highlight={highlight}
        aside={aside}>{text}
        <StyledTableOfContentsProgress
            progress={highlight ? progress*100 : 0} />
    </StyledTableOfContentsLink>
);

export default TableOfContentsLink;