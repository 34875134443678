import React from 'react';
import MediaQuery from 'react-responsive';

import TableOfContentsWords from 'components/layout/TableOfContentsWords';
import TableOfContentsFull from 'components/layout/TableOfContentsFull';

const TableOfContents = (props) => {
    const renderDesktop = () => {
        return (<TableOfContentsFull aside {...props} />);
    };

    const renderMobile = () => {
        return (<TableOfContentsWords {...props} />);
    }

    return (
        <React.Fragment>
            <MediaQuery minWidth={1040}>
                {renderDesktop()}
            </MediaQuery>
            <MediaQuery maxWidth={1039}>
                {renderMobile()}
            </MediaQuery>
        </React.Fragment>
    );
};

export default TableOfContents;