import React from 'react';
import styled, { css } from 'styled-components';
import reactStringReplace from 'react-string-replace';

import TableOfContentsHighlight from 'components/layout/TableOfContentsHighlight';
import TableOfContentsLink from 'components/layout/TableOfContentsLink';

const StyledTableOfContents = styled.aside`
    letter-spacing: -1px;
    color: #A29583;

    ${props => props.aside && css`
        font-size: 18px;
        line-height: 24px;

        @media screen and (min-width: 1040px) {
            position: sticky;
            top: 40px;

            display: block;
            height: 100%;
            max-height: 100vh;
            overflow-y: scroll;

            flex: 0 0 360px;

            padding: 30px 60px;
        }

        @media screen and (max-device-height: 900px) {
            font-size: 16px;
            line-height: 20px;
        }

        @media screen and (min-width: 1281px) {
            font-size: 18px;
            line-height: 24px;

            padding: 60px;
        }
    `}

    ${props => !props.aside && css`
        font-size: 20px;
        line-height: 26px;

        @media screen and (min-width: 768px) {
            font-size: 24px;
            line-height: 30px;
        }

        @media screen and (min-width: 1281px) {
            font-size: 27px;
            line-height: 32px;
        }

        @media screen and (max-device-height: 900px) {
            font-size: 20px;
            line-height: 26px;
        }
    `}
`;

class TableOfContents extends React.Component {
    state = {
        hoverItem: null,
    }

    handleMouseEnter = (id) => {
        this.setState({
            hoverItem: id,
        });
    }

    handleMouseLeave = () => {
        this.setState({
            hoverItem: null,
        });
    }

    getProgress = (item) => {
        const {aside} = this.props;

        if (aside) {
            return item.progress;
        } else {
            return (item.target === this.state.hoverItem) ? 1 : 0;
        }
    }

    renderItem = (item, index) => {
        const {aside, activeItemId} = this.props;

        if (item.blankLine && !aside) {
            return (<br key={index} />);
        }

        if (item.showOnlyInEssay && aside) {
            return;
        }

        return !item.navItem ?
                (<TableOfContentsHighlight
                    active={false}
                    bold={item.bold}
                    key={index}>{item.text}&nbsp;</TableOfContentsHighlight>): (
                <TableOfContentsHighlight
                    handleMouseEnter={() => this.handleMouseEnter(item.target)}
                    handleMouseLeave={() => this.handleMouseLeave()}
                    active={activeItemId === item.target}
                    aside={aside}
                    bold={item.bold}
                    target={item.target}
                    key={item.target}>
                    {reactStringReplace(item.text, /@(\w+)/g, (match, i) => (
                        <TableOfContentsLink
                            highlight
                            aside={aside}
                            key={item.target}
                            progress={this.getProgress(item)}
                            text={match} />
                    ))}
                </TableOfContentsHighlight>
            )
    }

    render() {
        const {aside, items} = this.props
        if (!items) {
            return null;
        }

        return (
            <StyledTableOfContents
                aside={aside}>
                {items.map((item, index) => this.renderItem(item, index))}
            </StyledTableOfContents>
        );
    }
}

export default TableOfContents;