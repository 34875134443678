import React from 'react';
import styled from 'styled-components';
import Flickity from 'flickity';
import 'flickity/dist/flickity.css';

import TableOfContentsHighlight from 'components/layout/TableOfContentsHighlight';
import TableOfContentsLink from 'components/layout/TableOfContentsLink';

const StyledTableOfContentsInner = styled.div`
    display: flex;

    .flickity-viewport {
        width: 100%;
        padding: 0 15px;
    }

    .flickity-slider {
        display: flex;
        flex-wrap: nowrap;

        > * {
            display: block;
            white-space: nowrap;
            flex-shrink: 0;
            padding: 20px 10px;
        }
    }
`;

const StyledTableOfContents = styled.nav`
    letter-spacing: -1px;
    color: #A29583;
    font-size: 18px;
    line-height: 24px;

    position: fixed;
    bottom: 0;
    z-index: 999;

    height: 60px;
    width: 100%;

    background: #fef7ea;

    box-shadow: 0px -20px 40px rgba(227, 215, 196, .5);
`;

class TableOfContents extends React.Component {
    constructor(props) {
        super(props);
        this.container = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const {activeItemId} = this.props;

        if (activeItemId && activeItemId !== prevProps.activeItemId) {
            const activeItem = this.getNavigationItems().filter(item => item.id === activeItemId);

            if (activeItem) {
                this.flckty.select(activeItem[0].index);
            }
        }
    }

    componentDidMount() {
        this.flckty = new Flickity(this.container.current, { // eslint-disable-line no-unused-vars
            pageDots: false,
            prevNextButtons: false,
            freeScroll: true,
            contain: true,
        });

        setTimeout(() => {
            this.flckty.reloadCells();
        }, 300);
    }

    handleItemSelect = (index) => {
        if (!this.flckty) {
            return;
        }

        this.flckty.select(index);
    }

    getNavigationItems = () => {
        const {items} = this.props;

        return items.filter(item => item.navItem).map((item, index) => ({
            ...item,
            index,
        }));
    }

    render() {
        const {activeItemId, items, aside} = this.props;

        if (!items) {
            return null;
        }

        return (
            <StyledTableOfContents
                aside={aside}>
                <StyledTableOfContentsInner ref={this.container}>
                    {this.getNavigationItems().map(item =>
                        (
                            <TableOfContentsHighlight
                                index={item.index}
                                active={activeItemId === item.target}
                                target={item.target}
                                key={item.target}>
                                <TableOfContentsLink
                                    handleClick={() => this.handleItemSelect(item.index)}
                                    highlight={activeItemId === item.target}
                                    text={item.navText}
                                    progress={item.progress} />
                            </TableOfContentsHighlight>
                        )
                    )}
                </StyledTableOfContentsInner>
            </StyledTableOfContents>
        );
    }
}

export default TableOfContents;