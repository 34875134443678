import React from 'react';
import styled, { css } from 'styled-components';
import posed from 'react-pose';
import { Link } from 'react-router-dom';

const PosedHighlight = posed.span({
    active: {
        fontWeight: 800,
        x: -20,
        transition: {
            duration: 100,
            ease: 'linear',
        },
    },
    inactive: {
        fontWeight: 400,
        x: 0,
        transition: {
            duration: 100,
            ease: 'linear',
        },
    },
});

const StyledHighlight = styled(PosedHighlight)`
    position: relative;

    text-decoration: none;

    @media screen and (min-width: 768px) {
        display: block;
    }

    ${props => !props.aside && props.bold && css`
        font-weight: 900 !important;
    `}

    ${props => props.active && props.aside && css`
        padding: 10px 0;

        font-size: 26px;
        line-height: 32px;

        @media screen and (max-device-height: 900px) {
            font-size: 20px;
            line-height: 26px;
        }

        @media screen and (min-width: 1281px) {
            padding: 20px 0;
        }

        &::before {
            content: '';
            width: 95px;
            height: 1px;
            background-color: #E3D7C4;
            position: absolute;
            left: -105px;
            top: 50%;
            z-index: 1;
        }`
    }
`;

const StyledLink = styled(Link)`
    color: inherit;
    text-decoration: none;
`;

class TableOfContentsHighlight extends React.Component {
    handleOnClick = (event) => {
        const {target} = this.props;

        if (!target) {
            event.preventDefault();
        }
    }

    getPose = () => {
        const {active, aside} = this.props;

        if (!aside) {
            return null;
        }

        return (active && aside) ?  'active' : 'inactive';
    }

    render() {
        const {
            active,
            target,
            progress,
            aside,
            bold,
            children,
        } = this.props;

        return (
            <React.Fragment>
                { target ? (
                    <StyledLink to={`/${target}`}>
                        <StyledHighlight
                            onMouseEnter={this.props.handleMouseEnter}
                            onMouseLeave={this.props.handleMouseLeave}
                            onClick={this.handleOnClick}
                            active={active}
                            progress={progress}
                            aside={aside}
                            bold={bold}
                            pose={this.getPose()}>
                            {children}&nbsp;
                        </StyledHighlight>
                    </StyledLink>)
            : ( <StyledHighlight
                    onClick={this.handleOnClick}
                    active={active}
                    progress={progress}
                    aside={aside}
                    bold={bold}
                    pose={this.getPose()}>
                    {children}&nbsp;
                </StyledHighlight>
            )}
        </React.Fragment>
        );
    }
}

export default TableOfContentsHighlight;