import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import { easing } from 'popmotion';

import { ReactComponent as Logo } from 'ui/logo.svg';
import Essay from 'components/layout/TableOfContentsFull';
import StartReading from 'components/layout/StartReading';

const StyledIntro = styled.article`
    position: relative;
    height: calc(100% - 40px);
    width: 100%;
    min-height: calc(100vh - 39px);
    transform: translateY(-40px);

    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 1440px;
    margin: 0 auto;

    padding: 120px 20px 80px;

    color: #001F42;

    @media screen and (max-device-height: 900px) {
        padding: 40px 0 20px;
    }

    @media (max-width: 1039px) {
        padding: 80px 20px 50px;

        min-height: 100%;
        justify-content: flex-start;
        flex-direction: column;
    }
`;

const AnimatedIntroImage = posed.span({
    unpopped: {
        opacity: 0,
    },
    popped: {
        opacity: 1,
        transition: {
            duration: 800,
            ease: [.5, .02, .25, 1],
        },
    },
});

const StyledIntroImage = styled(AnimatedIntroImage)`
    flex-shrink: 0;

    @media (max-width: 1039px) {
        max-width: 100px;
    }
`;

const AnimatedIntroText = posed.p({
    unpopped: {
        opacity: 0,
    },
    popped: {
        opacity: 1,
        transition: {
            opacity: {
                type: 'keyframes',
                duration: 3000,
                values: [0, 1, 1, 0],
                times: [0, .2, .8, 1],
                easings: [
                    easing.cubicBezier(.75, 0, .45, 1),
                    easing.cubicBezier(.75, 0, .45, 1),
                    easing.cubicBezier(.75, 0, .45, 1),
                ],
            },
        },
    },
});

const AnimatedEssay = posed.div({
    unpopped: {
        opacity: 0,
    },
    popped: {
        opacity: 1,
        transition: {
            opacity: {
                type: 'keyframes',
                duration: 1000,
                values: [0, 1],
                easings: [
                    easing.cubicBezier(.75, 0, .45, .8),
                ],
            },
        },
    },
});

const StyledIntroText = styled(AnimatedIntroText)`
    max-width: 880px;

    font-size: 36px;
    line-height: 42px;

    @media (min-width: 1040px) {
        font-size: 60px;
        line-height: 72px;
    }
`;

const StyledIntroSide = styled.div`
    display: flex;
    align-items: center;
    margin-top: 5vh;

    @media (min-width: 1040px) {
        flex: 0 0 660px;
        margin-top: 0;
        margin-left: 10%;
    }
`;

class Intro extends React.Component {
    handleIntroPoseComplete = () => {
        const {handleIntroDone} = this.props;

        handleIntroDone();
    }

    handleEssayPoseComplete = () => {
        const {handleEssayDone} = this.props;

        handleEssayDone();
    }

    getInitialPose = () => {
        const {introDone, essayDone} = this.props;

        return (introDone && essayDone) ? null : 'unpopped';

    }

    getAnimationPose = () => {
        const {introDone, essayDone} = this.props;

        return (introDone && essayDone) ? null : 'popped';
    }

    render() {
        const {
            table,
            introDone,
            introLink,
            introText,
        } = this.props;

        return (
            <StyledIntro id="intro">
                <StyledIntroImage
                    initialPose={this.getInitialPose()}
                    pose={this.getAnimationPose()}>
                    <Logo />
                </StyledIntroImage>
                <StyledIntroSide>
                    {introDone ?
                        (
                        <AnimatedEssay
                            initialPose={this.getInitialPose()}
                            pose={this.getAnimationPose()}
                            onPoseComplete={this.handleEssayPoseComplete}>
                            <Essay
                            items={table}
                            aside={false} />
                        </AnimatedEssay>) :
                        (<StyledIntroText
                            initialPose={this.getInitialPose()}
                            pose={this.getAnimationPose()}
                            onPoseComplete={this.handleIntroPoseComplete}
                    >{introText}</StyledIntroText>)}
                </StyledIntroSide>
                { introDone && <StartReading
                    show={introDone}
                    link={introLink} /> }
            </StyledIntro>
        );
    }
}


export default Intro;
