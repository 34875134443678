import React from 'react';
import Loadable from 'react-loadable';
import { upperFirst } from 'lodash';
import styled from 'styled-components';

import Loader from 'components/layout/Loader';

const StyledContentBlocks = styled.div`
    max-width: calc(780px + 2*20px);

    margin-left: auto;
    margin-right: auto;

    padding-left: 20px;
    padding-right: 20px;

    font-size: 20px;
    line-height: 25px;

    color: #505050;

    > *:first-child {
        margin-top: 20px;
    }

    > *:last-child {
        margin-bottom: 60px;
    }

    > * + * {
        margin-top: 30px;
    }
`;

class ContentBlocks extends React.Component {
    shouldComponentUpdate() {
        return false;
    }

    render() {
        const {blocks} = this.props;

        return (
            <StyledContentBlocks>
                {blocks.map((block, index) => {
                    const name = upperFirst(block.type);
                    const ContentBlock = Loadable({
                        loader: () => import(`components/content-blocks/${name}`),
                        loading: Loader,
                        timeout: 2000,
                        delay: 1000,
                    });

                    return (<ContentBlock key={index} {...block}/>);
                })}
            </StyledContentBlocks>
        );
    }
}

export default ContentBlocks;