import React, { Component } from 'react'
import styled from 'styled-components';
import ScrollPercentage from 'react-scroll-percentage';

import Hero from 'components/layout/Hero';
import ContentBlocks from 'components/content-blocks/ContentBlocks';

const StyledArticle = styled.article`
    margin-bottom: 60px;
    min-height: 100vh;
`;

class Article extends Component {
    state = {
        inView: false,
    }

    handleScrollPercentageChange = (percentage, inView) => {
        const {id, setArticleProgress} = this.props;

        setArticleProgress(id, percentage, inView);

        this.setState({
            inView: inView,
        });
    }

    render() {
        const {id, item} = this.props;
        const {inView} = this.state;

        return (
            <ScrollPercentage
                onChange={this.handleScrollPercentageChange}>
                <StyledArticle
                    id={id}>
                    <Hero
                        inView={inView}
                        title={item.title}
                        description={item.description}
                        author={item.author}
                        cover={item.coverPhoto} />
                    {item.contentBlocks.length && <ContentBlocks
                        blocks={item.contentBlocks} />}
                </StyledArticle>
            </ScrollPercentage>
        );
    }
}

export default Article;