import React from 'react';
import { tween } from 'popmotion';

import Intro from 'components/layout/Intro';
import Header from 'components/layout/Header';
import Container from 'components/layout/Container';
import Main from 'components/layout/Main';
import Article from 'containers/Article';
import Footer from 'components/layout/Footer';
import TableOfContents from 'containers/TableOfContents';

class Homepage extends React.Component {
    constructor(props) {
        super(props);

        const {location} = this.props;
        let loaded = false;

        if (location.pathname !== '/') {
            loaded = true;
        }

        this.state = {
            introDone: loaded,
            essayDone: loaded,
        };
    }

    componentDidMount = () => {
        const {location} = this.props;
        const id = location.pathname.replace('/', '');

        if (id) {
            setTimeout(() => this.scrollToActiveItem(id), 150);
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {location, history} = this.props;

        if (history.action === 'PUSH') {
            const id = location.pathname.replace('/', '');

            if (id) {
                this.scrollToActiveItem(id);
            }
        }
    }

    scrollToActiveItem = (id) => {
        const element = document.querySelector(`#${id}`);
            if (element) {
                const top = element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo(0, top)
            }
    }

    handleIntroDone = () => {
        this.setState({
            introDone: true,
        });
    }

    handleEssayDone = () => {
        this.setState({
            essayDone: true,
        });
    }

    render() {
        const {data} = this.props;
        const {introDone, essayDone} = this.state;

        return (
            <React.Fragment>
                <Header
                    deTijdLogo />
                <Intro
                    introDone={introDone}
                    essayDone={essayDone}
                    handleIntroDone={this.handleIntroDone}
                    handleEssayDone={this.handleEssayDone}
                    introLink={`/${data.items[0].id}`}
                    introText={data.intro}
                    table={data.tableOfContents} />
                { introDone && essayDone && (
                    <div>
                        <Header
                            sticky />
                        <Container>
                            <Main>
                                {data.items.map(item => (
                                    <Article
                                        key={item.id}
                                        id={item.id}
                                        item={item} />
                                ))}
                                <Footer />
                            </Main>
                            <TableOfContents
                                table={data.tableOfContents} />
                        </Container>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default Homepage;